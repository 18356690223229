import BaseSection from "./BaseSection";
import AppConstraint from "../layout/AppConstraint";
import CenteredLayout from "../layout/CenteredLayout";
import ParagraphSectionRegular from "./ParagraphSectionRegular";
import {Trans, useTranslation} from "react-i18next";
import KeywordSection from "./KeywordSection";
import SmallImage from "../image/SmallImage";
import marinaDroneShot from "../../assets/marina-drone-shot-small.webp";
import PortalElement from "../navigation/PortalElement";
import scrollToTarget from "../../utils/ScrollFunction";

/**
 * The main introduction section for the website.
 *
 * @returns {JSX.Element} the introduction section
 */
const IntroductionSection = () => {
    // fetch text for desired language
    const {t} = useTranslation();

    return (
        <BaseSection className={"white fill-view-height safety-vertical-padding"} id={"intro-section"}>
            <AppConstraint>
                <CenteredLayout className={"vertical space-between fill-container-height large-vertical-padding"}>
                    <CenteredLayout className={"vertical small-gap intro-and-portal no-pad"}
                                    id={"intro-and-portals-row"}>
                        <ParagraphSectionRegular header={t("homePage.introParagraph.header")}
                                                 text={<Trans i18nKey="homePage.introParagraph.text"/>}
                                                 className={"small-image-paragraph"}/>
                        <CenteredLayout id={"portal-and-paragraph-layout"}
                                        className={"no-pad grow medium-gap dynamic-centered-layout"}>
                            <KeywordSection/>
                            <SmallImage src={marinaDroneShot} alt={t("imgAlt.marinaDroneShot")}/>
                        </CenteredLayout>
                    </CenteredLayout>
                    <CenteredLayout className={"vertical small-gap intro-and-portal no-pad"}
                                    id={"intro-and-portals-column"}>

                        <ParagraphSectionRegular header={t("homePage.introParagraph.header")}
                                                 text={<Trans i18nKey="homePage.introParagraph.text"/>}
                                                 className={"small-image-paragraph"}/>
                        <KeywordSection/>
                        <SmallImage src={marinaDroneShot}/>
                    </CenteredLayout>
                    <div data-aos={"fade-up"}>
                        <PortalElement icon={"alternate_email"}
                                       text={t("portal.contact")}
                                       onClick={() => scrollToTarget("contact-section")}
                                       className={"vague-portal-element"}/>
                    </div>
                </CenteredLayout>


            </AppConstraint>
        </BaseSection>
    )
}

export default IntroductionSection;