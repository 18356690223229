import CenteredLayout from "../layout/CenteredLayout";
import Paragraph from "../text/Paragraph";
import EmailLink from "../link/EmailLink";
import SmallMap from "../navigation/SmallMap";
import BaseSection from "./BaseSection";
import DefaultCard from "../card/DefaultCard";
import {useTranslation} from "react-i18next";
import AppConstraint from "../layout/AppConstraint";

/**
 * A section displaying practical information about the business.
 *
 * @returns {JSX.Element} practical information section
 */
const PracticalInfoSection = () => {
    // fetch text for desired language
    const {t} = useTranslation();

    return (
        <BaseSection id={"contact-section"} className={"blue-pattern fill-view-height safety-vertical-padding"}>
            <AppConstraint>
                <CenteredLayout className={"large-gap dynamic-centered-layout"}>
                    <div data-aos={"fade-right"}>
                        <DefaultCard header={t("card.contact.header")}>
                            <Paragraph text={t("card.contact.paragraph")}/>
                            <EmailLink email={"kjaernesmarina@outlook.com"}/>
                        </DefaultCard>
                    </div>
                    <div data-aos={"fade-left"}>
                        <DefaultCard header={t("card.location.header")}>
                            <Paragraph text={t("card.location.paragraph")} className={"margin-20-bot"}/>
                            <SmallMap/>
                        </DefaultCard>
                    </div>
                </CenteredLayout>
            </AppConstraint>
        </BaseSection>
    )
}

export default PracticalInfoSection;