import "./MediumHeader.css"

/**
 * A text header of medium size.
 *
 * @param text the text to display
 * @param className additional class names to add
 * @returns {JSX.Element} the medium header
 */
const MediumHeader = ({text, className}) => {
    return (
        <h2 className={"medium-header " + className}>
            {text}
        </h2>
    )
}

export default MediumHeader;