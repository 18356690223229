import "./BaseSection.css"

/**
 * The base section for dividing pages into sections.
 * Dividing pages using this component ensures consistency across the whole website.
 *
 * @param children the children to add to the section
 * @param className class name for optional styling
 * @param id the id of the section
 * @returns {JSX.Element} the base section
 */
const BaseSection = ({children, className, id}) => {
    return (
        <section className={"base-section section " + className} id={id}>
            {children}
        </section>
    )
}

export default BaseSection;