import CenteredLayout from "../layout/CenteredLayout";
import BigHeader from "../text/BigHeader";
import Paragraph from "../text/Paragraph";
import "./ParagraphSectionRegular.css"

/**
 * A regular paragraph section, consisting of a header, a paragraph and an image.
 *
 * @param header the header of the section
 * @param text the text of the paragraph
 * @param img the image element
 * @param imgAlt alternative text for the image
 * @param className additional classnames
 * @returns {JSX.Element} the paragraph section
 */
const ParagraphSectionRegular = ({header, text, img, imgAlt, className}) => {
    return (
        <section className={"paragraph-section-regular " + className}>
            <CenteredLayout className={"vertical width1000 no-pad"}>
                <BigHeader text={header}></BigHeader>
                <CenteredLayout className={"vertical medium-gap"}>
                    <Paragraph text={text}></Paragraph>
                </CenteredLayout>
            </CenteredLayout>
        </section>
    )
}

export default ParagraphSectionRegular;