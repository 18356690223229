import "./NavBar.css"
import AppConstraint from "../layout/AppConstraint";

/**
 * The main navigation bar for navigating the website.
 *
 * @param children the children of the navigation bar
 * @returns {JSX.Element} the main navigation bar
 */
const NavBar = ({children}) => {
    return (
        <AppConstraint>
            <div className={"navbar"}>
                {children}
            </div>
        </AppConstraint>
    )
}

export default NavBar;