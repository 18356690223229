import './ElegantButton.css';

/**
 * A standard application button with an elegant design.
 *
 * @param text the text to display
 * @param onClick the function called when the button is clicked
 * @returns {JSX.Element} an elegant button
 */
const ElegantButton = ({ text, onClick }) => {
    return (
        <button className="elegant-button" onClick={onClick}>
            {text}
        </button>
    );
};

export default ElegantButton;