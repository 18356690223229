import "./ActionButton.css"

/**
 * A button in the shape of a Google icon, executing some function on click.
 *
 * @param icon the icon to render
 * @param action the function to execute
 * @param className additional class names
 * @returns {JSX.Element} the action button
 */
const ActionButton = ({icon, action, className}) => {
    return (
        <button className={"action-button-button " + className}>
            <span className={"material-symbols-outlined action-button"} onClick={action}>
                {icon}
            </span>
        </button>
    )
}

export default ActionButton;