import "./CenteredLayout.css"

/**
 * A layout component centering children both horizontally and vertically.
 *
 * @param children the children to add
 * @param className additional class names for styling
 * @param id the id of the component
 * @returns {JSX.Element} the centered layout with its children
 */
const CenteredLayout = ({children, className, id}) => {
    return (
        <div id={id} className={"centered-layout " + className}>
            {children}
        </div>
    )
}

export default CenteredLayout;