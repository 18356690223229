import {useTranslation} from "react-i18next";
import BaseSection from "../../section/BaseSection";
import ElegantButton from "../../button/ElegantButton";
import {useNavigate} from "react-router-dom";

/**
 * A page displayed when an unavailable page is requested.
 *
 * @returns {JSX.Element} the "page not found" page
 */
const PageNotFound = () => {
    // fetch text for desired language
    const {t} = useTranslation();

    // establishes the useNavigate function
    const navigate = useNavigate();

    /**
     * Navigates the user to a given page.
     *
     * @param page the directory to navigate to (for example `/home`)
     */
    const goToPage = (page) => {
        navigate(page);
    }

    return (
        <BaseSection className={"white"}>
            <h1>
                {t("error.pageNotFound.header")}
            </h1>
            <ElegantButton text={t("error.pageNotFound.goHomeButton")} onClick={() => goToPage("/home")}/>
        </BaseSection>
    )
}

export default PageNotFound;