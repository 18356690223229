import "./Paragraph.css"

/**
 * A paragraph of text.
 * The component is simply a `<p>` element to which is applied standard application styling.
 *
 * @param text the text of the paragraph
 * @param className additional class names for styling
 * @returns {JSX.Element} the paragraph
 */
const Paragraph = ({text, className}) => {
    return (
        <p className={"paragraph " + className}>
            {text}
        </p>
    )
}

export default Paragraph;