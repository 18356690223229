import "./SmallImage.css"

/**
 * A small image component.
 *
 * @param src the image source
 * @param alt an alternative text for the image
 * @param className additional classnames
 * @returns {JSX.Element} the small image
 */
const SmallImage = ({src, alt, className}) => {
    return (
        <img src={src} className={"small-image image " + className} alt={alt}/>
    )
}

export default SmallImage;