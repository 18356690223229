import "./KeywordDisplay.css"

/**
 * An element displaying keywords with an associated icon.
 * Used to put highlight benefits and information to the user.
 *
 * @param icon the icon to display on the portal
 * @param text the text to display on the portal
 * @param className additional class names
 * @returns {JSX.Element} the portal element
 */
const KeywordDisplay = ({icon, text, className}) => {
    return (
        <div className={"keyword-display " + className}>
            <span className={"material-symbols-outlined"}>
                {icon}
            </span>
            <label className={"keyword-display-label"}>
                {text}
            </label>
        </div>
    )
}

export default KeywordDisplay;