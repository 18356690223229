/**
 * A function that scrolls the webpage to a component with a given ID.
 *
 * @param targetId the ID of the target component
 */
const scrollToTarget = (targetId) => {
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
};

export default scrollToTarget;