import './App.css';
import './DynamicView.css'
import './Keyframes.css'
import './i18n';
import AOS from 'aos';
import 'aos/dist/aos.css';
import HomePage from "./components/page/homepage/HomePage";
import NavBar from "./components/navbar/NavBar";
import Logo from "./assets/dalle_logo_scaled_30_white.webp";
import NavElement from "./components/navbar/NavElement";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import PageNotFound from "./components/page/pagenotfound/PageNotFound";
import {useEffect} from "react";

/**
 * The main application component.
 * Every other component in the application will be a child of this.
 *
 * @returns {JSX.Element} the root component of the application
 */
const App = () => {
    useEffect(() => {
        AOS.init({
            duration: 1200, // Global animation duration
            once: true, // Whether animation should happen only once - while scrolling down
        });
    }, []); // The empty array causes this effect to only run once, after initial render

    return (
        <BrowserRouter>
            <div className={"App"}>
                <header>
                    <NavBar>
                        <NavElement destination={"/home"}>
                            <img src={Logo} alt={"Logo"} className={"nav-logo"}/>
                        </NavElement>
                    </NavBar>
                </header>
                <main>
                    <Routes>
                        <Route path="/" element={<HomePage/>}/>
                        <Route path="/home" element={<HomePage/>}/>
                        <Route path="/*" element={<PageNotFound/>}/>
                    </Routes>
                </main>
                <footer>

                </footer>
            </div>
        </BrowserRouter>
    )
        ;
}

export default App;
