import {GoogleMap, LoadScript} from '@react-google-maps/api';

// the initial center of the map
const center = {
    lat: 59.748257,
    lng: 10.726727
}

// styling for the map
const containerStyle = {
    width: '100%',
    height: '400px',
}

/**
 * A small map displaying the location of the business, using Google's Map API.
 *
 * @returns {JSX.Element} a small Google Map component
 */
const SmallMap = () => {
    return (
        <LoadScript googleMapsApiKey="AIzaSyBKla-XPNVchj0VJI6pTgxt_Rw4680TAQo">
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={15}
            >
            </GoogleMap>
        </LoadScript>
    )
}

export default SmallMap;