import CenteredLayout from "../layout/CenteredLayout";
import HeroBanner from "../page/homepage/HeroBanner";
import scrollToTarget from "../../utils/ScrollFunction";
import {useTranslation} from "react-i18next";
import BigImage from "../image/BigImage";
import textLogo from "../../assets/text-logo-white-v2-cropped.png"
import textLogoStacked from "../../assets/text-logo-white-v2-stacked-aligned.png"
import ActionButton from "../navigation/ActionButton";

/**
 * The hero section acting as the first introduction to the webpage.
 *
 * @returns {JSX.Element} the hero section
 */
const HeroSection = () => {
    // fetch text for desired language
    const {t} = useTranslation();

    return (
        <HeroBanner>
            <CenteredLayout className={"vertical"}>
                <BigImage src={textLogo} alt={t("imgAlt.mainTextLogo")} id={"main-text-logo-horizontal"} className={"main-text-logo"}/>
                <BigImage src={textLogoStacked} alt={t("imgAlt.mainTextLogo")} id={"main-text-logo-vertical"} className={"main-text-logo"}/>
                <ActionButton icon={"stat_minus_2"} action={() => scrollToTarget("intro-section")}
                              className={"absolute wobble-effect"}/>
            </CenteredLayout>
        </HeroBanner>
    )
}

export default HeroSection;