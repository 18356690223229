import "./NavElement.css"

/**
 * The default element of navigation in the main navigation bar.
 *
 * @param children the children of the navigation element
 * @param className additional class names
 * @param onClick function triggered when element is clicked
 * @returns {JSX.Element} a default navigation element
 */
const NavElement = ({children, className, destination}) => {
    return (
        <span className={"nav-element " + className} href={destination}>{children}</span>
    )
}

export default NavElement;