import "./BigHeader.css"

/**
 * A large header acting as a title for content on the webpage.
 *
 * @param text the text to display
 * @returns {JSX.Element} the large header
 */
const BigHeader = ({text}) => {
    return (
        <h1 className={"big-header"}>
            {text}
        </h1>
    )
}

export default BigHeader;