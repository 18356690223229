import KeywordDisplay from "../navigation/KeywordDisplay";
import "./KeywordSection.css"
import {useTranslation} from "react-i18next";

/**
 * A section dedicated to displaying portals on the website.
 *
 * @returns {JSX.Element} the portal section
 */
const KeywordSection = () => {
    // fetch text for desired language
    const {t} = useTranslation();

    return (
        <section className={"keyword-section"}>
            <div data-aos={"fade-right"}>
                <KeywordDisplay icon={"width"} text={t("keywordDisplay.width")}/>
            </div>
            <div data-aos={"fade-right"}>
                <KeywordDisplay icon={"local_parking"} text={t("keywordDisplay.parking")}/>
            </div>
        </section>
    )
}

export default KeywordSection;