import CenteredLayout from "../layout/CenteredLayout";
import MediumHeader from "../text/MediumHeader";
import ColorPlate from "../layout/ColorPlate";

/**
 * A default card, consisting of a header and added children.
 * The component acts as a stylish and systematic way of displaying information,
 * and usually goes well together with other cards in the same section.
 *
 * @param header the header of the card
 * @param children the children to add
 * @returns {JSX.Element} the default card
 */
const DefaultCard = ({header, children}) => {
    return (
        <ColorPlate className={"width600"}>
            <CenteredLayout className={"vertical"}>
                <MediumHeader text={header}></MediumHeader>
                {children}
            </CenteredLayout>
        </ColorPlate>
    )
}

export default DefaultCard;