import "./ColorPlate.css"

/**
 * A square plate in a given color, acting as a container for its children.
 * Color can be chosen adding prop `className` with any of the following values:
 * *
 * <p/>
 * The default color is gold.
 *
 * @param children the children to add to the plate
 * @param className additional class names for styling
 * @returns {JSX.Element} a colored plate with its children
 */
const ColorPlate = ({children, className}) => {
    return (
        <div className={"color-plate " + className}>
            {children}
        </div>
    )
}

export default ColorPlate;