import "./HeroBanner.css"

/**
 * A banner responsible for the graphical background of the hero section.
 * The banner acts as the container for the contents of the hero section, as well
 * as a visual effect.
 *
 * @param children the content of the hero section
 * @returns {JSX.Element} the hero banner
 */
const HeroBanner = ({children}) => {
    return (
            <section className={"hero-banner-container"}>
                <div className={"hero-banner"}>
                    <div className={"darken-layer overlay"}></div>
                    <div className={"content-box"}>
                        {children}
                    </div>
                </div>
            </section>
    )
}

export default HeroBanner;