import "./BigImage.css"

/**
 * A big image.
 *
 * @param src the source of image
 * @param alt an alternative text for the image
 * @param id the id of the image
 * @param className additional class names
 * @returns {JSX.Element} the big image
 */
const BigImage = ({src, alt, id, className}) => {
    return (
        <img src={src} className={"big-image image " + className} alt={alt} id={id}/>
    )
}

export default BigImage;