import "./AppContraint.css"

/**
 * A layout component responsible for containing layout of content to application standards.
 *
 * @param children the content to wrap in the constraint
 * @returns {JSX.Element} the application constraint container
 */
const AppConstraint = ({children}) => {
    return (
        <div className={"app-constraint"}>
            {children}
        </div>
    )
}

export default AppConstraint;