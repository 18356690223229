import HeroSection from "../../section/HeroSection";
import PracticalInfoSection from "../../section/PracticalInfoSection";
import IntroductionSection from "../../section/IntroductionSection";

/**
 * The home page of the application.
 * The page serves as a first impression of the application, and contains crucial
 * information about the business that new costumers may find useful.
 *
 * @returns {JSX.Element} the home page component
 */
const HomePage = () => {
    return (
        <>
            <HeroSection/>
            <IntroductionSection/>
            <PracticalInfoSection/>
        </>
    )
}

export default HomePage;