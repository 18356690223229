import "./EmailLink.css"

/**
 * A link to an email address.
 * The link displays the full email address.
 *
 * @param email the email address to link to
 * @returns {JSX.Element} the email link
 */
const EmailLink = ({email}) => {
    return (
        <a href={`mailto:${email}`} className={"email-link"}>{email}</a>
    );
}

export default EmailLink;