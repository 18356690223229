import "./PortalElement.css"

/**
 * A component acting as a portal, taking the user to some destination when clicked.
 *
 * @param icon the icon of the portal
 * @param text the text of the portal
 * @param onClick the action executed when clicked
 * @param className additional class names
 * @returns {JSX.Element} the portal element
 */
const PortalElement = ({icon, text, onClick, className}) => {
    return (
        <div onClick={onClick} className={"portal-element " + className}>
            <span className={"material-symbols-outlined"}>
                {icon}
            </span>
            <label>
                {text}
            </label>
        </div>
    )
}

export default PortalElement;